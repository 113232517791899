import { get, filter } from 'lodash';
import { parseContent, parseText } from 'client/data/cms/content';
import { parseContentListLinks } from 'site-modules/shared/utils/parse-content-metadata';
import { SCORECARD_MAP } from 'site-modules/shared/constants/editorial-review';

export const PRE_PROD_ARTICLE_TYPE = {
  TEASER: 'teaser',
  MISCELLANEOUS: 'miscellaneous',
  EDMUNDS_SAYS: 'edmunds-says',
};

const PRE_PROD_PREDEFINED_TITLE = {
  'what-is-it': 'What is it?',
  'why-does-it-matter': 'Why does it matter?',
  'what-does-it-compete-with': 'What does it compete with?',
  'edmunds-says': 'Edmunds says',
};

const DEFAULT_EDMUNDS_PHOTOGRAPHER = 'Keith Buglewicz';

function getReviewImageLinks(editorialReview) {
  const photoEntry = editorialReview.hasChild('photo') ? editorialReview.child('photo') : {};
  const imageLinks = photoEntry && photoEntry.links && photoEntry.links();
  return imageLinks || [];
}

function getReviewImageLinkEntry(editorialReview, rel) {
  return getReviewImageLinks(editorialReview).find(link => link && link.rel === rel);
}

function getReviewImageLinkEntries(editorialReview, relRegex) {
  return getReviewImageLinks(editorialReview).reduce((accumulator, link) => {
    if (link && relRegex.test(link.rel)) {
      accumulator.push(link);
    }
    return accumulator;
  }, []);
}

function getContentEntry(content, id) {
  return content.hasChild(id) ? content.child(id) : parseContent({});
}

function getRatingValue(rating, key, postfix) {
  const value = parseFloat(rating.metadata(`${key}Rating${postfix}`).value()).toFixed(1);
  return !isNaN(value) ? value : undefined;
}

function buildRating(rating, key, postfix = '', fallbackText) {
  return {
    [key]: {
      // If not empty string
      text: rating.metadata(`${key}Text`).value() || fallbackText,
      title: rating.metadata(`${key}Title`).value(),
      rating: getRatingValue(rating, key, postfix),
      embeds: rating.children().map(embedEntry => ({
        id: embedEntry.id,
        ...embedEntry.getAllMetadata(),
      })),
    },
  };
}

/**
 * Create Safety Features object based on content data
 * @param entry misc entry
 * @return {[{ title: string, description: string }]}
 */
export function buildSafetyFeatures(entry) {
  if (!entry) {
    return [];
  }

  const features = [];
  let index = 1;
  while (index <= 3) {
    const title = entry.metadata(`safetyTitle${index}`).value();
    if (title) {
      features.push({
        title,
        description: entry.metadata(`safetyFeature${index}`).value(),
      });
    }
    index += 1;
  }

  return features;
}

function getFirstContentArticles(firstContentEntry) {
  return firstContentEntry
    .child('first-articles')
    .children()
    .map(article => {
      let author = null;
      let content = [];
      article.children().forEach(articleChildEntry => {
        if (articleChildEntry.id.includes('-author')) {
          author = articleChildEntry.child('author-info').getAllMetadata();
          return;
        }

        if (articleChildEntry.id.includes('-content')) {
          content = articleChildEntry.children().map(contentEntry => {
            const { id } = contentEntry;
            if (id.includes('-content')) {
              return {
                type: 'text',
                content: contentEntry.content,
                title: contentEntry.metadata('title').value(),
                subTitle: contentEntry.metadata('subTitle').value(),
              };
            } else if (id.includes('-photos')) {
              return {
                type: 'photos',
                links: contentEntry.links(),
              };
            } else if (id.includes('-video')) {
              return {
                type: 'video',
                videoIds: {
                  'youtube-videoid': contentEntry.metadata('youtube-videoid').value(),
                },
              };
            }
            return {};
          });
        }
      });

      return {
        author,
        content,
        publishDate: article.metadata('publishDate').value(),
        dateModified: article.metadata('dateModified').value(),
        metaDescription: article.metadata('metaDescription').value(),
        titleTag: article.metadata('titleTag').value(),
      };
    });
}

function getPreProdContentArticles(preProdContentEntry) {
  return preProdContentEntry
    .children()
    .map(article => {
      if (!article.metadata('htmlContent').value()) {
        return null;
      }

      const type = article.id.includes(PRE_PROD_ARTICLE_TYPE.TEASER)
        ? PRE_PROD_ARTICLE_TYPE.TEASER
        : PRE_PROD_ARTICLE_TYPE.MISCELLANEOUS;

      return {
        title: PRE_PROD_PREDEFINED_TITLE[article.id] || article.metadata('title').value(),
        content: article.metadata('htmlContent').value(),
        links: article.links(),
        type: article.id.includes('edmunds-says') ? PRE_PROD_ARTICLE_TYPE.EDMUNDS_SAYS : type,
        id: article.id,
      };
    })
    .filter(article => !!article);
}

/**
 * Create First Content object based on content data.
 * It contains first-time content, such as articles and media for vehicles, commonly used for preprod
 * Example atom file: edmunds/content/porsche/macan/2019/review.atom ('first-content' entry)
 * @param firstContentEntry
 * @param preProdContentEntry
 * @param vehicle
 * @return {FirstContentEntity}
 */
export function buildFirstContent(firstContentEntry, preProdContentEntry, vehicle = {}) {
  const hasPreProdContent = !!preProdContentEntry.children().length;

  const mediaTitle = `${vehicle.year ? `${vehicle.year} ` : ''}${vehicle.make ? `${vehicle.make} ` : ''}${
    vehicle.model ? `${vehicle.model}` : ''
  }`;
  const media = firstContentEntry
    .child('first-content-media')
    .links()
    .map(item => {
      if (!item.title && mediaTitle) {
        return { ...item, title: mediaTitle };
      }
      return item;
    });

  const additionalLinksContent = hasPreProdContent
    ? preProdContentEntry.child('pre-prod-content-links').child('content-list')
    : firstContentEntry.child('first-content-links').child('content-list');
  const bulletsContentMetadata = firstContentEntry.child('preprod-bullets').getAllMetadata();
  const releaseDate = {
    label: firstContentEntry.metadata('releaseDateTitle').value(),
    value: firstContentEntry.metadata('releaseDate').value(),
  };
  const articlePublishDate = firstContentEntry.metadata('publishDate').value();
  const articleUpdateDate = firstContentEntry.metadata('updatedDate').value();
  const subHeader = firstContentEntry.metadata('subHeader').value();
  const price = {
    label: firstContentEntry.metadata('priceTitle').value(),
    value: firstContentEntry.metadata('price').value(),
  };
  const bullets = {
    label: bulletsContentMetadata.bulletTitle || '',
    value: Object.keys(bulletsContentMetadata)
      .filter(name => name.includes('bullet-'))
      .sort((name1, name2) => parseInt(name1.replace('bullet-', ''), 10) - parseInt(name2.replace('bullet-', ''), 10))
      .map(name => bulletsContentMetadata[name]),
  };
  const browserTitle = firstContentEntry.metadata('browserTitle').value();
  const metaDescription = firstContentEntry.metadata('metaDescription').value();
  const additionalLinks = parseContentListLinks(additionalLinksContent);

  const articles = hasPreProdContent
    ? getPreProdContentArticles(preProdContentEntry)
    : getFirstContentArticles(firstContentEntry);
  const authorPath = firstContentEntry.metadata('authorPath').value();
  const hasTeaser = preProdContentEntry.hasChild('teaser') || firstContentEntry.hasChild('teaser');
  const hasMiscellaneous =
    preProdContentEntry.hasChild('miscellaneous-1') || firstContentEntry.hasChild('miscellaneous-1');

  return {
    hasPreProdContent,
    hasTeaser,
    hasMiscellaneous,
    media,
    articles,
    additionalLinks,
    releaseDate,
    price,
    bullets,
    browserTitle,
    metaDescription,
    articlePublishDate,
    articleUpdateDate,
    subHeader,
    authorPath,
  };
}

export function buildScorecardRatings(scorecardEntry) {
  const scorecardRatingEntries = scorecardEntry.children();

  if (!scorecardRatingEntries?.length) {
    return null;
  }

  return {
    overallRating: scorecardEntry.metadata('overallRating').number(),
    verdictText: scorecardEntry.metadata('verdictText').value(),
    scorecardRatings: SCORECARD_MAP.map(({ id, title, details }) => ({
      key: id,
      title,
      sectionText: scorecardEntry
        .child(id)
        .metadata('sectionText')
        .value(),
      sectionRating: scorecardEntry
        .child(id)
        .metadata('sectionRating')
        .number(),
      details: details
        .map(({ name, label }) => ({
          label,
          rating: scorecardEntry
            .child(id)
            .metadata(name)
            .number(),
        }))
        .filter(({ rating }) => rating),
    })).filter(({ sectionRating }) => sectionRating),
  };
}

export function getHeroImage(heroImage) {
  return (
    heroImage && {
      ...heroImage,
      author: heroImage?.rel === 'edmundsHero' && !heroImage?.author ? DEFAULT_EDMUNDS_PHOTOGRAPHER : heroImage?.author,
    }
  );
}

export function getMetadataValue(entry, key, defaultValue) {
  if (entry && entry.hasMetadata) {
    return entry.hasMetadata(key) ? entry.metadata(key).value(defaultValue) : defaultValue;
  }

  return defaultValue;
}

function getValuesString(data, format) {
  const keys = filter(Object.keys(data.getAllMetadata()), key => key.search(format) !== -1).sort();

  return keys.reduce((result, key) => `${result + data.metadata(key).value()};`, '');
}

export function transformMakeModelYear(apiResponse) {
  if (!Object.keys(apiResponse).length) {
    return null;
  }

  const content = parseContent(apiResponse);
  const vehicle = get(content, 'vehicles[0]');
  const reviewEntry = getContentEntry(content, 'review');
  const scorecardEntry = getContentEntry(content, 'scorecard');
  const ratingsOverall = getContentEntry(content, 'ratings-overall');
  const ratingsDriving = getContentEntry(content, 'ratings-driving');
  const ratingsComfort = getContentEntry(content, 'ratings-comfort');
  const ratingsInterior = getContentEntry(content, 'ratings-interior');
  const ratingsUtility = getContentEntry(content, 'ratings-utility');
  const ratingsTechnology = getContentEntry(content, 'ratings-technology');
  const ratingsMpg = getContentEntry(content, 'ratings-mpg');
  const ratingsValue = getContentEntry(content, 'ratings-value');
  const ratingsWildcard = getContentEntry(content, 'ratings-wildcard');
  const miscEntry = getContentEntry(content, 'misc');
  const firstContentEntry = getContentEntry(content, 'first-content');
  const heroImage = getReviewImageLinkEntry(content, 'edmundsHero') || getReviewImageLinkEntry(content, 'hero');
  const galleryImages = getReviewImageLinkEntries(content, /^gallery_/); // starts with gallery_
  const interiorImages = getReviewImageLinkEntries(content, /^interiorGallery_/); // starts with interiorGallery_
  const trimFeatures = getMetadataValue(reviewEntry, 'trimFeatures') || '';
  const PRO_FORMAT = /^pros?\d*$/;
  const CON_FORMAT = /^cons?\d*$/;
  const pros = getValuesString(reviewEntry, PRO_FORMAT) || getValuesString(content, PRO_FORMAT);
  const cons = getValuesString(reviewEntry, CON_FORMAT) || getValuesString(content, CON_FORMAT);
  // If overall rating has a 10 pt scale value, assign the 10 pt metadata postfix for remaining values
  const postfix = getRatingValue(ratingsOverall, 'overall', '-ten') ? '-ten' : '';

  const editorialReview = {
    heroImage: getHeroImage(heroImage),
    galleryImages,
    interiorImages,

    title: content.title,
    published: content.published,
    updated: content.updated,
    isTenPointScale: !!postfix,
    reviewSubmodels: getMetadataValue(content, 'reviewSubmodels'),
    reviewLink: getMetadataValue(content, 'reviewLink') !== 'false',
    body: getMetadataValue(content, 'body'),
    authorPath: getMetadataValue(reviewEntry, 'authorPath'),
    ratedByPath: getMetadataValue(reviewEntry, 'ratedByPath'),
    calloutQuote: getMetadataValue(reviewEntry, 'pullQuote'),
    edmundsSays: getMetadataValue(content, 'edmundsSays'),
    introduction: getMetadataValue(content, 'introduction'),
    review: getMetadataValue(content, 'review'),
    powertrain: getMetadataValue(content, 'powertrain'),
    safety: getMetadataValue(content, 'safety'),
    summary: getMetadataValue(reviewEntry, 'summary'),
    summarySnippet: getMetadataValue(reviewEntry, 'summarySnippet'),
    summaryTitle: getMetadataValue(reviewEntry, 'summaryTitle', 'Overview'),
    needToKnow: getMetadataValue(reviewEntry, 'needToKnow'),
    needToKnowTitle: getMetadataValue(reviewEntry, 'needToKnowTitle'),
    competitors: getMetadataValue(reviewEntry, 'competitors'),
    competitorsTitle: getMetadataValue(reviewEntry, 'competitorsTitle', 'Competitors to consider'),
    longTerm: getMetadataValue(reviewEntry, 'longTerm'),
    longTermTitle: getMetadataValue(reviewEntry, 'longTermTitle', 'What’s it like to live with?'),
    ratingDisclaimer: getMetadataValue(reviewEntry, 'carryoverRatingDisclaimer'),
    trimFeaturesTitle: getMetadataValue(reviewEntry, 'trimFeaturesTitle'),
    trimFeatures,
    trimFeaturesBodySnippet: trimFeatures.split('</p>')[0],
    trimFeaturesBody: trimFeatures
      .split('</p>')
      .slice(1)
      .join('</p>'),
    trimFeaturesSnippet: getMetadataValue(reviewEntry, 'trimFeaturesSnippet'),
    trimTested: getMetadataValue(reviewEntry, 'trimTested'),
    weRecommend: getMetadataValue(reviewEntry, 'weRecommend'),
    weRecommendTitle: getMetadataValue(reviewEntry, 'weRecommendTitle'),
    goodCar: getMetadataValue(reviewEntry, 'goodCar'),
    estimatedRange: parseInt(getMetadataValue(reviewEntry, 'estimatedRange'), 10),
    whatsNew: getMetadataValue(miscEntry, 'whatsNew') || getMetadataValue(content, 'whatsNew'),

    ratings: {
      ...buildRating(ratingsOverall, 'overall', postfix),

      ...buildRating(ratingsDriving, 'acceleration', postfix),
      ...buildRating(ratingsDriving, 'brakes', postfix),
      ...buildRating(ratingsDriving, 'drivability', postfix),
      ...buildRating(ratingsDriving, 'handling', postfix),
      ...buildRating(ratingsDriving, 'offRoad', postfix),
      ...buildRating(ratingsDriving, 'steering', postfix),

      ...buildRating(ratingsComfort, 'comfort', postfix),
      ...buildRating(ratingsComfort, 'climateControl', postfix),
      ...buildRating(ratingsComfort, 'noiseVibration', postfix),
      ...buildRating(ratingsComfort, 'rideComfort', postfix),
      ...buildRating(ratingsComfort, 'seatComfort', postfix),

      ...buildRating(ratingsInterior, 'drivingPosition', postfix),
      ...buildRating(ratingsInterior, 'easeUse', postfix),
      ...buildRating(ratingsInterior, 'gettingInOut', postfix),
      ...buildRating(ratingsInterior, 'quality', postfix),
      ...buildRating(ratingsInterior, 'roominess', postfix),
      ...buildRating(ratingsInterior, 'visibility', postfix),
      ...buildRating(ratingsInterior, 'convertibletop', postfix),

      ...buildRating(ratingsUtility, 'cargoSpace', postfix),
      ...buildRating(ratingsUtility, 'childSafetySeat', postfix),
      ...buildRating(ratingsUtility, 'hauling', postfix), // need to add
      ...buildRating(ratingsUtility, 'smallItemStorage', postfix),
      ...buildRating(ratingsUtility, 'towing', postfix), // need to add
      ...buildRating(ratingsUtility, 'utility', postfix),

      ...buildRating(ratingsTechnology, 'technology', postfix),
      ...buildRating(ratingsTechnology, 'audioNav', postfix),
      ...buildRating(ratingsTechnology, 'smartphone', postfix),
      ...buildRating(ratingsTechnology, 'driverAids', postfix),
      ...buildRating(ratingsTechnology, 'voiceControl', postfix),
      ...buildRating(ratingsTechnology, 'mobileWeb', postfix),

      ...buildRating(ratingsMpg, 'mpg', postfix),

      ...buildRating(ratingsValue, 'value', postfix),

      ...buildRating(ratingsWildcard, 'wildcard', postfix),

      ...buildRating(ratingsDriving, 'driving', postfix, content.metadata('driving').value()),
      ...buildRating(ratingsInterior, 'interior', postfix, content.metadata('interior').value()),
    },
    pros: parseText(pros),
    cons: parseText(cons),
    safetyFeatures: buildSafetyFeatures(miscEntry),

    firstContent: buildFirstContent(firstContentEntry, parseContent({}), vehicle),
    scorecard: buildScorecardRatings(scorecardEntry),
  };

  if (content.id) {
    editorialReview.id = content.id;
  }

  return editorialReview;
}

export function transformPreProdContent(apiResponse, isSubmodelSpecific) {
  if (!Object.keys(apiResponse).length) {
    return null;
  }

  const content = parseContent(apiResponse);
  const { updated, published } = content;
  const firstContentEntry = getContentEntry(content, 'first-content');
  const preProdContentEntry = getContentEntry(content, 'pre-prod-content');
  const reviewSubmodels = getMetadataValue(content, 'reviewSubmodels');

  return {
    ...buildFirstContent(firstContentEntry, preProdContentEntry),
    dateModified: updated,
    publishDate: published,
    reviewSubmodels: reviewSubmodels ? JSON.parse(reviewSubmodels.replace(/'/g, '"')) : undefined,
    titleTag: getMetadataValue(content, 'titleTag'),
    isSubmodelSpecific,
  };
}
