export const OLD_REVIEW_FORMAT_LAST_YEAR = 2019;

export const RATINGS_MAP = {
  DRIVING: {
    title: 'Driving',
    value: 'driving',
    children: [
      { title: 'Acceleration', value: 'acceleration' },
      { title: 'Braking', value: 'brakes' },
      { title: 'Steering', value: 'steering' },
      { title: 'Handling', value: 'handling' },
      { title: 'Drivability', value: 'drivability' },
      { title: 'Off-road', value: 'offRoad' },
    ],
  },
  COMFORT: {
    title: 'Comfort',
    value: 'comfort',
    children: [
      { title: 'Seat comfort', value: 'seatComfort' },
      { title: 'Ride comfort', value: 'rideComfort' },
      { title: 'Noise & vibration', value: 'noiseVibration' },
      { title: 'Climate control', value: 'climateControl' },
    ],
  },
  INTERIOR: {
    title: 'Interior',
    value: 'interior',
    children: [
      { title: 'Ease of use', value: 'easeUse' },
      { title: 'Getting in/getting out', value: 'gettingInOut' },
      { title: 'Driving position', value: 'drivingPosition' },
      { title: 'Roominess', value: 'roominess' },
      { title: 'Visibility', value: 'visibility' },
      { title: 'Quality', value: 'quality' },
      { title: 'Convertible top', value: 'convertibletop' },
    ],
  },
  UTILITY: {
    title: 'Utility',
    value: 'utility',
    children: [
      { title: 'Small-item storage', value: 'smallItemStorage' },
      { title: 'Cargo space', value: 'cargoSpace' },
      { title: 'Child safety seat accommodation', value: 'childSafetySeat' },
      { title: 'Towing', value: 'towing' },
      { title: 'Hauling', value: 'hauling' },
    ],
  },
  TECHNOLOGY: {
    title: 'Technology',
    value: 'technology',
    children: [
      { title: 'Audio & navigation', value: 'audioNav' },
      { title: 'Smartphone integration', value: 'smartphone' },
      { title: 'Driver aids', value: 'driverAids' },
      { title: 'Voice control', value: 'voiceControl' },
      { title: 'Mobile web', value: 'mobileWeb' },
    ],
  },
};

export const SCORECARD_MAP = [
  {
    id: 'driving',
    title: 'Driving Experience',
    details: [
      { name: 'acceleration', label: 'Acceleration' },
      { name: 'braking', label: 'Braking' },
      { name: 'steering', label: 'Steering' },
      { name: 'handling', label: 'Handling' },
      { name: 'driveability', label: 'Driveability' },
      { name: 'visibility', label: 'Visibility' },
      { name: 'rideQuality', label: 'Ride Comfort' },
      { name: 'driverAids', label: 'Driver assistance' },
    ],
  },
  {
    id: 'comfort',
    title: 'Comfort',
    details: [
      { name: 'seatComfortFront', label: 'Seat Comfort - front seats' },
      { name: 'seatComfortSecondRow', label: 'Seat Comfort - 2nd row' },
      { name: 'seatComfortThirdRow', label: 'Seat Comfort - 3rd row' },
      { name: 'gettingInFront', label: 'Getting in & out - front seats' },
      { name: 'gettingInSecond', label: 'Getting in & out - 2nd row' },
      { name: 'gettingInThird', label: 'Getting in & out - 3rd row' },
      { name: 'carSeatSecond', label: 'Car seat - 2nd row' },
      { name: 'carSeatThird', label: 'Car seat - 3rd row' },
      { name: 'driverControls', label: 'Driver controls' },
      { name: 'interiorNoise', label: 'Interior noise & vibration' },
      { name: 'climateHvac', label: 'Climate control' },
    ],
  },
  {
    id: 'in-cabin-tech',
    title: 'In-Cabin Tech',
    details: [
      { name: 'infotainment', label: 'In-car display' },
      { name: 'deviceIntegration', label: 'Phone connectivity & charging' },
    ],
  },
  {
    id: 'storage-cargo-towing',
    title: 'Storage, cargo & towing',
    details: [
      { name: 'cargoSpace', label: 'Cargo Space' },
      { name: 'smallItems', label: 'Small Item Storage' },
      { name: 'towing', label: 'Towing' },
    ],
  },
  {
    id: 'good-value',
    title: 'Is it a good value?',
    details: [
      { name: 'buildQuality', label: 'Build Quality' },
      { name: 'featuresVCost', label: 'Features vs Cost' },
      { name: 'warranty', label: 'Warranty & perks' },
    ],
  },
  {
    id: 'mpg-or-range',
    title: 'Fuel Economy',
    details: [
      { name: 'range', label: 'Range' },
      { name: 'efficiency', label: 'Efficiency' },
      { name: 'charging', label: 'Charging' },
    ],
  },
  {
    id: 'wow-factor',
    title: 'Wow Factor',
    details: [],
  },
];
